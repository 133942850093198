
import { Component, Vue } from "vue-property-decorator";
import Categoria from "@/components/Noticias/Categoria.vue";

@Component({
  components: {
    Categoria
  }
})
export default class CategoriaView extends Vue {}
